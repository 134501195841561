/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm } from 'react-hook-form';
import { DiGithub } from 'react-icons/di';
import { customAlphabet } from 'nanoid';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { track } from '../../../v2/tracking';
import { z } from 'zod';
import { authSignup } from '../../modules/apis/neo/authSignup';

export const nanoid = customAlphabet(
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
  24
);

interface FormValues {
  email: string;
  password: string;
}

export const passwordCheck = (str: string) => {
  const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
  return re.test(str);
};

export const Signup = ({ openAuth }: { openAuth: () => void }) => {
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {},
  });
  const navigate = useNavigate();

  const onSubmit = async (data: FormValues) => {
    const parsedCredentials = z
      .object({
        email: z.string().email(),
        password: z.string().min(8),
      })
      .safeParse(data);

    if (parsedCredentials.error) {
      toast.warn(
        parsedCredentials.error.errors[0].message.replace(
          'String',
          parsedCredentials.error.errors[0].path.join('')
        ),
        {
          autoClose: 2000,
          position: 'top-center',
        }
      );
      return;
    }

    if (passwordCheck(data?.password) === false) {
      toast.warn(
        'Password should be at least 8 characters, max 16, with at least a number, upper and lower case letters',
        {
          autoClose: 2000,
          position: 'top-center',
        }
      );
      return;
    }

    try {
      const res = await authSignup({ ...data, user_name: nanoid(10) }, {
        authorize: true,
      } as any);
      if (res.data) {
        toast.success(
          'Signup successful. Please check your email to verify your account.',
          {
            autoClose: 2000,
            position: 'top-center',
          }
        );
        setTimeout(() => {
          navigate(`/aiweb/veirfy/${data.email}`);
        }, 1500);
      }
    } catch (error: any) {
      if (error.response.data.error) {
        switch (error.response.data.error) {
          case 'user is already existed':
            toast.warn(
              'Email is already registered. Please login to continue.',
              {
                autoClose: 2000,
                position: 'top-center',
              }
            );
            return;
          case 'please activate your account':
            // redirect to verify page
            toast.warn(
              'Email is waiting for activation. Please check your email to verify your account.',
              {
                autoClose: 2000,
                position: 'top-center',
              }
            );
            // TODO verify
            return;
        }
      }
      toast.warn(
        error.response.data.error ||
          'Something went wrong during signup. Please try again.',
        {
          autoClose: 2000,
          position: 'top-center',
        }
      );
    }
  };

  return (
    <div className='bg-[#fff] mt-8 pb-10'>
      <div className='bg-[#FBFCFD] px-8 py-6 flex justify-between items-center'>
        <span className=' font-bold'>Sign Up</span>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className='w-full px-8'
      >
        <div className='form-control w-full mt-6'>
          <label className='label'>
            <span className='label-text'>Email</span>
          </label>
          <input
            placeholder='email@example.com'
            type='email'
            className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
            {...register('email')}
          />
        </div>
        <div className='form-control w-full mt-4'>
          <label className='label'>
            <span className='label-text'>Password</span>
          </label>
          <input
            placeholder='**********'
            type='password'
            className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
            {...register('password')}
          />
        </div>

        <button
          className='btn btn-primary bg-[#263DFF] mt-10 w-full'
          onClick={handleSubmit(onSubmit)}
        >
          Register
        </button>
      </form>
      <div className='text-center mt-5 text-sm px-8 font-semibold'>
        <span className='text-sm'>
          <Link
            to='/aiweb/login'
            className='text-[#263DFF] hover:cursor-pointer font-bold'
          >
            Return to Login
          </Link>
        </span>
      </div>
      <div className='px-8 mt-5'>
        <HorizontalLine className='h-0 mb-5' />
        <button
          className='btn btn-outline gap-5 w-full normal-case text-xl'
          onClick={() => openAuth()}
        >
          <DiGithub size='1.5rem' />
          Continue with Google
        </button>
      </div>
    </div>
  );
};
