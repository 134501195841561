/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authReset } from '../../modules/apis/neo/authReset';
import { authForgot } from '../../modules/apis/neo/authForgot';

const Reset = () => {
  const { emailAddress } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const verificationToken = searchParams.get('token');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(emailAddress!);

  const handleSendEmail = async () => {
    try {
      await authForgot({ email }, { authorize: true } as any);
      toast.success('Reset email sent successfully. Please check your email.', {
        autoClose: 2000,
        position: 'top-center',
      });
    } catch (error) {
      toast.error('Something went wrong. Please try again.', {
        autoClose: 2000,
        position: 'top-center',
      });
    }
  };
  const handleReset = async () => {
    try {
      await authReset({ token: verificationToken!, password }, {
        authorize: true,
      } as any);

      toast.success(
        'Password reset successfully. . Please login to continue.',
        {
          autoClose: 2000,
          position: 'top-center',
        }
      );
      setTimeout(() => {
        navigate('/aiweb/login');
      }, 1500);
    } catch (error: any) {
      if (error.response.data.error) {
        switch (error.response.data.error) {
          case 'token is expired':
            toast.warn('Invalid token. Please try again.');
            return;
          case 'invalid token':
            toast.warn('Failed to reset password. Please send email again.');
            return;

          default:
            break;
        }
      }
      toast.error(
        error.response.data.error ||
          'Something went wrong during verification. Please try again.'
      );
    }
  };

  return (
    <div className='bg-[#fff] mt-8 pb-10'>
      <div className='bg-[#FBFCFD] px-8 py-6 flex justify-between items-center'>
        <span className=' font-bold'>Reset your password</span>
      </div>
      {emailAddress && verificationToken ? (
        <div className='p-8'>
          <h3>Enter your new password</h3>
          <input
            placeholder='**********'
            type='password'
            className='input input-bordered w-full border-transparent bg-[#F8F9FA] my-4 '
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
      ) : (
        <div className='p-8'>
          <h3>
            Enter your user account&apos;s verified email address and we will
            send you a password reset link.
          </h3>
          <input
            placeholder='Enter you email address'
            type='text'
            className='input input-bordered w-full border-transparent bg-[#F8F9FA] my-4 '
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
      )}

      <div className='px-8 pb-8'>
        <button
          className='btn btn-primary bg-[#263DFF] mt-10 w-full capitalize'
          onClick={() => {
            if (emailAddress && verificationToken) {
              handleReset();
            } else {
              handleSendEmail();
            }
          }}
        >
          {emailAddress && verificationToken
            ? 'Submit'
            : 'Send Password Reset Email'}
        </button>
      </div>
    </div>
  );
};

export default Reset;
