import { Alert, Card, Table } from 'antd';
import { useAIAuth } from '../../../components/OpenAuth/useOpenAuth';
import { useMemo } from 'react';
import moment from 'moment';
import { ColumnProps } from 'antd/es/table';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const apiKeyColumns: ColumnProps<any>[] = [
  {
    dataIndex: 'name',
    title: 'Name',
  },
  {
    dataIndex: 'id',
    title: 'Value',
    render: (text) => {
      return (
        <div>
          {text.slice(0, 3)}...{text.slice(-4)}
          <CopyToClipboard text={text}>
            <CopyOutlined
              className='ml-2'
              rev={undefined}
              onClick={() => {
                toast.success('Copied to clipboard');
              }}
            />
          </CopyToClipboard>
        </div>
      );
    },
  },
  {
    dataIndex: 'is_default',
    title: 'Default',
    render: (text) => {
      return text ? 'Yes' : 'No';
    },
  },
  {
    dataIndex: 'created_at',
    title: 'Created Date',
    render: (text) => {
      return text ? moment(text).fromNow() : '--';
    },
  },
];

export default function AuthApikey() {
  const [auth] = useAIAuth();

  const apiKeys = useMemo(() => {
    if (auth?.profile?.api_keys) {
      return auth?.profile?.api_keys;
    }
    return [];
  }, [auth]);

  return (
    <main>
      <h3 className='text-lg font-bold my-4'>Api Keys</h3>
      <Card
        bordered={false}
        style={{ boxShadow: 'none' }}
        headStyle={{ borderBottom: '0 none', padding: 0 }}
        bodyStyle={{ padding: 0 }}
        title={<h5 className='text-lg font-bold'>User Api Keys</h5>}
      >
        <p>
          Api-Key authenticate your identity to the Zettablock AI and allow CLI
          to perform actions based on token permissions.
        </p>
        <Alert
          className='my-4'
          type='warning'
          message='Do not share your Api-Key with anyone; we regularly check for leaked Api-Key and remove them immediately.'
        />
        <Table
          columns={apiKeyColumns}
          dataSource={apiKeys}
          pagination={false}
        />
      </Card>
    </main>
  );
}
