import { memo, MouseEventHandler, useMemo, useState } from 'react';
import { useAIAuth } from '../OpenAuth/useOpenAuth';
import classNames from 'classnames';

export const Avatar = memo(
  ({
    className = 'w-10',
    onClick,
  }: {
    className?: string;
    onClick?: MouseEventHandler;
  }) => {
    const [showInitial, setShowInitial] = useState(false);
    const [auth] = useAIAuth();

    const initial = useMemo(() => {
      if (auth?.user?.user_name) {
        return (
          <div>
            {auth.user.user_name.split(' ').map((e: string) => e.charAt(0))}
          </div>
        );
      }
      return '';
    }, [auth]);

    if (auth?.profile?.avatar_url) {
      return (
        <div className='avatar placeholder' onClick={onClick}>
          <div
            className={classNames(
              'text-neutral-content rounded-full bg-neutral-focus',
              className
            )}
          >
            {showInitial ? (
              initial
            ) : (
              <img
                onError={() => {
                  setShowInitial(true);
                }}
                src={auth.profile.avatar_url}
                referrerPolicy='no-referrer'
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <div className='avatar placeholder' onClick={onClick}>
        <div
          className={classNames(
            'text-neutral-content rounded-full bg-neutral-focus',
            className
          )}
        >
          {initial}
        </div>
      </div>
    );
  }
);
