/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable max-classes-per-file */
import { IsOptional, ValidateNested } from 'class-validator';
import { ClassConstructor, Type } from 'class-transformer';
import { httpClient, IsOptionalString } from '../../../http-client';
import { AxiosRequestConfig } from 'axios';

export namespace authResendDTO {
  export interface Req {
    email: string;
  }
  export class Res {
    @IsOptionalString()
    data?: string;
  }

  export class Response {
    @IsOptional()
    @ValidateNested()
    @Type(() => Res)
    res?: Res | null;
  }
}

export const authResend = (
  reqData: authResendDTO.Req,
  config = {} as AxiosRequestConfig & {
    dto: ClassConstructor<authResendDTO.Res>;
  }
) => {
  config.dto = authResendDTO.Res;
  return httpClient.post<authResendDTO.Res>('/register/activation', reqData, config);
};
