import { Button, Form, Input, Upload } from 'antd';
import { useAIAuth } from '../../../components/OpenAuth/useOpenAuth';
import { useEffect } from 'react';

export default function AuthProfile() {
  const [form] = Form.useForm();
  const [auth] = useAIAuth();

  useEffect(() => {
    if (auth) {
      form.setFieldsValue({
        ...auth.user,
        avatar_url: auth.profile?.avatar_url,
        git_token: auth.profile?.git_token,
      });
    }
  }, [auth]);

  return (
    <main>
      <h3 className='text-lg font-bold my-4'>Profile Settings</h3>
      <Form layout='vertical' disabled form={form} className='w-[80%]'>
        <Form.Item label='Email' name='email'>
          <Input />
        </Form.Item>
        <Form.Item label='Full name' name='user_name'>
          <Input />
        </Form.Item>
        <Form.Item label='Avatar（optional）' name='avatar_url'>
          <Upload>
            <Button>Upload Avatar Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item label='Github username（optional）' name='git_name'>
          <Input placeholder='Github account' />
        </Form.Item>
        <Form.Item label='Github token（optional）' name='git_token'>
          <Input placeholder='Github token' />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type='primary' htmlType='submit'>
            Save Settings
          </Button>
        </Form.Item>
      </Form>
    </main>
  );
}
