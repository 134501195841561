import { Avatar, Button, Card, Col, Empty, Row, Space, Tag } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import { OptionGroup } from '../../../components/OptionGroup';
import { useNavigator } from '../../../useNavigator';
import { useEffect, useState } from 'react';
import { assetList, assetListDTO } from '../../../modules/apis/neo/assetList';
import { RepositoryType } from '../../../modules/apis/types';
import { modalityTypeOptions } from '../../../modules/apis/options';
import moment from 'moment';

export default function DatasetList() {
  const [modality, setModality] = useState();
  const [state, setState] = useState([] as assetListDTO.Asset[]);
  const navigator = useNavigator();
  useEffect(() => {
    assetList({ type: RepositoryType.RepositoryTypeDataset }).then((res) => {
      const data = res.data ?? [];
      if (modality) {
        setState(data.filter((item) => item.modality === modality));
      } else {
        setState(data);
      }
    });
  }, [modality]);
  const len = state.length;

  return (
    <main>
      <Card
        title={
          <Space>
            <h3 className='text-lg'>Data</h3>
            <span className='text-xs text-gray-300 font-normal'>
              {len} {len > 1 ? 'Data sources' : 'Data source'}
            </span>
          </Space>
        }
        extra={
          <Button
            type='primary'
            icon={<AppstoreOutlined rev={undefined} />}
            onClick={() => {
              navigator.push('/aiweb/dataset/create');
            }}
          >
            Create Dataset
          </Button>
        }
        bordered={false}
        style={{ boxShadow: 'none' }}
        headStyle={{ padding: 0 }}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <OptionGroup
          options={modalityTypeOptions}
          value={modality}
          onChange={setModality}
          allowClear
          size='small'
          type='default'
          activeType='default'
        />
        {state.length ? (
          <Row gutter={[16, 16]} className='mt-5'>
            {state.map((item) => {
              return (
                <Col xs={24} sm={12} span={12} lg={8} xl={6} key={item.id}>
                  <Card
                    title={
                      <div>
                        <h5 className='font-bold text-lg line-clamp-1'>
                          {item.name}
                        </h5>
                      </div>
                    }
                    headStyle={{ borderBottom: '0 none' }}
                    bodyStyle={{ paddingTop: 0 }}
                    hoverable
                    onClick={() => {
                      navigator.push(`/aiweb/datasets/${item.id}`);
                    }}
                  >
                    <div className='line-clamp-2 my-3 h-[44px] text-gray-400'>
                      {item.description}
                    </div>
                    <Space className='flex justify-between'>
                      <Space className='text-xs text-gray-300'>
                        <Tag>{item.modality}</Tag>
                        <span>{moment(item.updated_at || 0).fromNow()}</span>
                      </Space>
                      <div>
                        <Avatar
                          src={item.creator?.avatar_url}
                          className='w-5 h-5'
                        />
                        <span className='ml-3'>{item.creator?.user_name}</span>
                      </div>
                    </Space>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Empty description='Data sources is empty' className='mt-5' />
        )}
      </Card>
    </main>
  );
}
