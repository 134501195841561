import { EventEmitter } from 'eventemitter3';
import { LoginError } from './AuthError';
import { Deferred, getPopupFeatures } from './util';

export interface PopupResponse {
  sessionId?: string;
  sessionNamespace?: string;
  state?: string;
  error?: string;
}

export interface PopupHandlerEvents {
  close: [];
}

export class WindowPopup extends EventEmitter<PopupHandlerEvents> {
  url: string;

  target: string;

  features: string;

  window: Window | null;

  windowTimer?: number;

  iClosedWindow: boolean;

  timeout: number;

  codeDeferred = new Deferred();

  constructor({
    url,
    target,
    features,
    timeout = 30000,
  }: {
    url: string;
    target?: string;
    features?: string;
    timeout?: number;
  }) {
    super();
    this.url = url;
    this.target = target || '_blank';
    this.features = features || getPopupFeatures();
    this.window = null;
    this.windowTimer = undefined;
    this.iClosedWindow = false;
    this.timeout = timeout;
  }

  _setupTimer(): void {
    if (this.windowTimer) {
      clearInterval(this.windowTimer);
      this.codeDeferred = new Deferred();
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.windowTimer = Number(
      setInterval(() => {
        if (that.window && that.window.closed) {
          clearInterval(that.windowTimer);
          setTimeout(() => {
            if (!that.iClosedWindow) {
              that.emit('close');
            }
            that.iClosedWindow = false;
            that.window = null;
          }, that.timeout);
        }
        if (that.window === undefined) clearInterval(that.windowTimer);
        try {
          const search = that.window?.name || '';
          if (search.startsWith('?code=')) {
            that.codeDeferred.resolve(search);
            that.close();
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          //
        }
      }, 500)
    );
  }

  open(): void {
    this.window = window.open(this.url, this.target, this.features);
    this._setupTimer();
    if (!this.window) throw LoginError.popupBlocked();
    if (this.window?.focus) this.window.focus();
  }

  close(): void {
    this.iClosedWindow = true;
    if (this.window) this.window.close();
  }

  redirect(locationReplaceOnRedirect: boolean): void {
    if (locationReplaceOnRedirect) {
      window.location.replace(this.url);
    } else {
      window.location.href = this.url;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async listenOnChannel(): Promise<any> {
    this.close();

    return new Promise((resolve, reject) => {
      this.on('close', () => {
        reject(LoginError.popupClosed());
      });

      try {
        this.open();
      } catch (error) {
        reject(error);
      }

      this.codeDeferred.promise.then(resolve, reject);
    });
  }
}
