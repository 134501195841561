import { Button, Space } from 'antd';
import { ButtonType } from 'antd/es/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { CloseOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

interface Option {
  value: string | number;
  label: ReactNode;
  disabeld?: boolean;
}
interface OptionGroupProps<M, V> {
  value?: V;
  onChange?: (value: V | undefined) => void;
  options?: Option[];
  activeType?: ButtonType;
  type?: ButtonType;
  allowClear?: boolean;
  mode?: M;
  size?: SizeType;
}

export function OptionGroup<
  M extends 'multiple' | 'single',
  V = M extends 'multiple' ? string | number : Array<string | number>
>({
  value,
  onChange,
  options = [],
  activeType = 'primary',
  type = 'default',
  allowClear,
  size,
  mode,
}: OptionGroupProps<M, V>) {
  const isMatch = (option: Option) => {
    if (mode === 'multiple') {
      return ((value ?? []) as Array<string | number>).includes(option.value);
    }
    return (value as unknown as string | number) === option.value;
  };
  return (
    <Space>
      {options.map((option, index) => {
        return (
          <Button
            key={index}
            type={isMatch(option) ? activeType : type}
            size={size}
            danger={isMatch(option) && activeType === 'default'}
            onClick={() => {
              if (onChange) {
                if (mode === 'multiple') {
                  const arr = (value ?? []) as Array<string | number>;
                  const pos = arr.findIndex((v) => v === option.value);
                  if (pos > -1) {
                    if (allowClear || arr.length > 1) {
                      arr.splice(pos, 1);
                    }
                  } else {
                    arr.push(option.value);
                  }
                  onChange(value as unknown as V);
                } else {
                  const ivalue = option.value as unknown as V;
                  if (allowClear && ivalue === value) {
                    onChange(undefined);
                  } else {
                    onChange(ivalue);
                  }
                }
              }
            }}
          >
            {option.label}
          </Button>
        );
      })}
      {allowClear &&
        (Array.isArray(value) ? !!value.length : value !== undefined) && (
          <Button
            size={size}
            type='text'
            icon={<CloseOutlined rev={undefined} />}
            onClick={() => {
              onChange?.(undefined);
            }}
          >
            Clear
          </Button>
        )}
    </Space>
  );
}
