/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useMemo, useState } from 'react';

interface GlobalSettingsContextProps {
  isMiniSidebar?: boolean;
  toggleMiniSidebar?: () => void;
  setIsMiniSidebar?: (isMiniSidebar: boolean) => void;
}

const initialState: GlobalSettingsContextProps = {
  isMiniSidebar: false,
  toggleMiniSidebar: () => {},
};

export const GlobalSettingsContext =
  createContext<GlobalSettingsContextProps>(initialState);

export const GlobalSettingsContextProvider = ({ children }: any) => {
  const [isMiniSidebar, setIsMiniSidebar] = useState<boolean>(false);
  const toggleMiniSidebar = () => {
    setIsMiniSidebar(!isMiniSidebar);
  };

  const value = useMemo(() => {
    return {
      isMiniSidebar,
      setIsMiniSidebar,
      toggleMiniSidebar,
    };
  }, [isMiniSidebar]);

  return (
    <GlobalSettingsContext.Provider value={value}>
      {children}
    </GlobalSettingsContext.Provider>
  );
};
