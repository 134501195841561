/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authVerify } from '../../modules/apis/neo/authVerify';
import { authResend } from '../../modules/apis/neo/authResend';

const Verify = () => {
  const { emailAddress } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleResendVerificationEmail = async () => {
    try {
      await authResend({ email: emailAddress! }, { authorize: true } as any);
      toast.success(
        'Verification email sent successfully. Please check your email.',
        {
          autoClose: 2000,
          position: 'top-center',
        }
      );
    } catch (error) {
      toast.error('Something went wrong. Please try again.', {
        autoClose: 2000,
        position: 'top-center',
      });
    }
  };

  useEffect(() => {
    const verificationToken = searchParams.get('token');

    const verifyAndRedirect = async () => {
      if (verificationToken) {
        try {
          await authVerify({ token: verificationToken }, {
            authorize: true,
          } as any);

          toast.success(
            'Email verified successfully. Please login to continue.',
            {
              autoClose: 2000,
              position: 'top-center',
            }
          );
          setTimeout(() => {
            navigate('/aiweb/login');
          }, 1000);
        } catch (error: any) {
          if (error.response.data.error) {
            switch (error.response.data.error) {
              case 'email is already activated':
                toast.warn(
                  'Email is already activated. Please login to continue.',
                  {
                    autoClose: 2000,
                    position: 'top-center',
                  }
                );
                navigate('/aiweb/login');
                return;
              case 'invalid activation':
                toast.warn('Invalid activation token. Please try again.');
                return;
              case 'failed to activate':
                toast.warn('Failed to activate. Please try again.');
                return;

              default:
                break;
            }
          }
          toast.error(
            error.response.data.error ||
              'Something went wrong during verification. Please try again.'
          );
        }
      }
    };

    verifyAndRedirect();
  }, [searchParams, emailAddress]);

  return (
    <div className='bg-[#fff] mt-8 pb-10'>
      <div className='bg-[#FBFCFD] px-8 py-6 flex justify-between items-center'>
        <span className=' font-bold'>Verify Your Account</span>
      </div>
      <div className='p-8'>
        <p className='mb-4'>We&apos;ve sent a verification email to:</p>

        <p className='mb-4 font-semibold'>{emailAddress}</p>

        <p className='mb-4'>
          {' '}
          Go and check it before it expires! Confirm your account by clicking
          the verification link. Check your spam folder if you don&apos;t see
          the email.
        </p>
      </div>
      <div className='px-8 pb-8'>
        <button
          className='btn btn-primary bg-[#263DFF] mt-10 w-full capitalize'
          onClick={handleResendVerificationEmail}
        >
          Resend Verification Email
        </button>
      </div>
    </div>
  );
};

export default Verify;
