/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import {
  ModelListDashboardItem,
  ModelListTenantItem,
  ModelQuerySummary,
  ModelUser,
  PoProject,
} from '../api/__gen__/data-contracts';
import {
  getMarketplaceDashboards,
  getMarketplaceQueries,
  getPublicApiProjects,
  getPublicTableProjects,
} from './workspace/apis';
import { getFetchCommunityApis } from '../modules/api/api-rpc';
import { getTenants } from './explore/components/apis';
import { useRecoilValue } from 'recoil';
import { AuthState } from '../modules/auth/AuthState';

export const deduplicateAuthor = (arr: ModelUser[]) => {
  return arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
};

export enum ExploreDataEnum {
  ALL = 'all',
  TABLES = 'tables',
  DASHBOARDS = 'dashboards',
  QUERIES = 'queries',
  GRAPHQL = 'graphql',
  APIS = 'apis',
}

export const useExploreData = (includes = [ExploreDataEnum.ALL]) => {
  const [publicTables, setPublicTables] = useState<PoProject[]>([]);
  const [publicApis, setPublicApis] = useState<PoProject[]>([]);
  const [publicDashboards, setPublicDashboards] = useState<
    ModelListDashboardItem[]
  >([]);
  const [publicQueries, setPublicQueries] = useState<ModelQuerySummary[]>([]);
  const [publicGraphQlApis, setPublicGraphQlApis] = useState<any[]>([]);

  const [queryAuthors, setQueryAuthors] = useState<ModelListTenantItem[]>([]);
  const [dashboardAutors, setDashboardAutors] = useState<ModelListTenantItem[]>(
    []
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useRecoilValue(AuthState);

  const fetchAndSetPublicProjects = async () => {
    setIsLoading(true);
    const apiProjects = await getPublicApiProjects();
    setPublicApis(apiProjects);

    if (
      includes.includes(ExploreDataEnum.TABLES) ||
      includes.includes(ExploreDataEnum.ALL)
    ) {
      const tableProjects = await getPublicTableProjects();
      setPublicTables(tableProjects);
    }

    if (
      includes.includes(ExploreDataEnum.DASHBOARDS) ||
      includes.includes(ExploreDataEnum.ALL)
    ) {
      const dashboards = await getMarketplaceDashboards();
      setPublicDashboards(dashboards || []);

      const dashboardAutorsData = await getTenants('dashboard');
      setDashboardAutors(dashboardAutorsData || []);
    }

    if (
      includes.includes(ExploreDataEnum.QUERIES) ||
      includes.includes(ExploreDataEnum.ALL)
    ) {
      const queries = await getMarketplaceQueries();
      setPublicQueries(queries || []);

      const queryAuthorsData = await getTenants('query');
      setQueryAuthors(queryAuthorsData || []);
    }

    if (
      includes.includes(ExploreDataEnum.GRAPHQL) ||
      includes.includes(ExploreDataEnum.ALL)
    ) {
      const graphQlApis = (await getFetchCommunityApis())?.data?.items || [];
      setPublicGraphQlApis(graphQlApis);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (auth?.id) {
      setTimeout(() => {
        fetchAndSetPublicProjects();
      }, 10);
    }
  }, [auth?.id]);

  return {
    publicApis,
    publicTables,
    publicDashboards,
    publicGraphQlApis,
    refecth: fetchAndSetPublicProjects,
    isLoading,
    publicQueries,
    queryAuthors,
    dashboardAutors,
  };
};
