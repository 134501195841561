import { Avatar, Button, Card, Col, Empty, Row, Space, Tag } from 'antd';
import { CodepenOutlined } from '@ant-design/icons';
import { OptionGroup } from '../../../components/OptionGroup';
import { useNavigator } from '../../../useNavigator';
import { useEffect, useState } from 'react';
import { assetList, assetListDTO } from '../../../modules/apis/neo/assetList';
import { RepositoryType } from '../../../modules/apis/types';
import {
  getOrgInfoByName,
  inferenceTypeOptions,
} from '../../../modules/apis/options';
import moment from 'moment';

export default function ModelList() {
  const [modality, setModality] = useState();
  const [state, setState] = useState([] as assetListDTO.Asset[]);
  const navigator = useNavigator();
  useEffect(() => {
    assetList({ type: RepositoryType.RepositoryTypeModel }).then((res) => {
      const data = res.data ?? [];
      if (modality) {
        setState(data.filter((item) => item.modality === modality));
      } else {
        setState(data);
      }
    });
  }, [modality]);
  const len = state.length;

  return (
    <main>
      <Card
        title={
          <Space>
            <h3 className='text-lg'>Models</h3>
            <span className='text-xs text-gray-300 font-normal'>
              {len} {len > 1 ? 'Models' : 'Model'}
            </span>
          </Space>
        }
        extra={
          <Button
            type='primary'
            icon={<CodepenOutlined rev={undefined} />}
            onClick={() => {
              navigator.push('/aiweb/model/create');
            }}
          >
            Create Model
          </Button>
        }
        bordered={false}
        style={{ boxShadow: 'none' }}
        headStyle={{ padding: 0 }}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <OptionGroup
          options={inferenceTypeOptions}
          value={modality}
          onChange={setModality}
          allowClear
          size='small'
          type='default'
          activeType='default'
        />
        {state.length ? (
          <Row gutter={[16, 16]} className='mt-5'>
            {state.map((item) => {
              const orgInfo = getOrgInfoByName(item.name);
              return (
                <Col xs={24} sm={12} span={12} lg={8} xl={6} key={item.id}>
                  <Card
                    title={
                      <Space>
                        <Avatar src={orgInfo[1]} className='w-5 h-5' />
                        {orgInfo[0]}
                      </Space>
                    }
                    headStyle={{ borderBottom: '0 none' }}
                    bodyStyle={{ paddingTop: 0 }}
                    hoverable
                    onClick={() => {
                      navigator.push(`/aiweb/models/${item.id}`);
                    }}
                  >
                    <h5 className='font-bold text-lg line-clamp-1'>
                      {item.name}
                    </h5>
                    <div className='line-clamp-2 my-3 h-[44px] text-gray-400'>
                      {item.description}
                    </div>
                    <Space className='text-xs text-gray-300'>
                      <Tag>{item.modality}</Tag>
                      <span>{moment(item.updated_at || 0).fromNow()}</span>
                    </Space>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Empty description='Models is empty' className='mt-5' />
        )}
      </Card>
    </main>
  );
}
