/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiGithub } from 'react-icons/di';
import { toast } from 'react-toastify';

import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { track } from '../../../v2/tracking';
import { z } from 'zod';
import { authSignin } from '../../modules/apis/neo/authSignin';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { useAIAuth } from './useOpenAuth';
import { passwordCheck } from './Signup';

interface FormValues {
  username_or_email: string;
  password: string;
}

export const Signin = ({ openAuth }: { openAuth: () => void }) => {
  const { register, handleSubmit } = useForm<FormValues>({});
  const [, { setAuth }] = useAIAuth();
  const navigate = useNavigate();

  const onSubmit = async (data: FormValues) => {
    const parsedCredentials = z
      .object({
        username_or_email: z.string().email(),
        password: z.string().min(8),
      })
      .safeParse(data);

    if (parsedCredentials.error) {
      toast.warn(
        parsedCredentials.error.errors[0].message.replace(
          'String',
          parsedCredentials.error.errors[0].path.join('')
        ),
        {
          autoClose: 2000,
          position: 'top-center',
        }
      );
      return;
    }
    if (passwordCheck(data?.password) === false) {
      toast.warn(
        'Password should be at least 8 characters, max 16, with at least a number, upper and lower case letters',
        {
          autoClose: 2000,
          position: 'top-center',
        }
      );
      return;
    }

    try {
      const res = await authSignin({ ...data }, { authorize: true } as any);
      if (res.data?.user) {
        const { user } = res.data;
        setAuth(res.data);
        track('Auth - Login', {
          type: 'Email',
          isZettaBlockUser: user.email?.includes('zettablock'),
          ...user,
          tenant: user?.tenant || 'community',
          aiWeb: true,
        });

        toast.success('Signin successful.', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        //
        switch (error?.response?.data?.message) {
          case "password is not matched: crypto/bcrypt: bcrypt hashes must start with '$', but hashedSecret started with '&'":
            toast.warn('Password not matched. Please try again.', {
              autoClose: 2000,
              position: 'top-center',
            });
            return;
          case 'user is not found':
            toast.warn('User not found. Please try again.', {
              autoClose: 2000,
              position: 'top-center',
            });
            return;
          case 'the user email had signed up and waiting for activation':
            toast.warn(
              'User not activated. Please check your email to verify your account.',
              {
                autoClose: 2000,
                position: 'top-center',
              }
            );
            // TODO verify
            return;
          default:
            break;
        }
      }
      // eslint-disable-next-line no-console
      console.warn('error', error);
      toast.warn(
        (error as any).message ||
          'Something went wrong during signup. Please try again.'
      );
    }
  };

  return (
    <div className='bg-[#fff] mt-8 pb-10'>
      <div className='bg-[#FBFCFD] px-8 py-6 flex justify-between items-center'>
        <span className=' font-bold'>Login</span>

        <span className='text-sm'>
          Don&rsquo;t have an account?{' '}
          <Link
            to='/aiweb/signup'
            className='text-[#263DFF] hover:cursor-pointer font-bold'
          >
            Sign up
          </Link>
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full px-8'>
        <div className='form-control w-full mt-6'>
          <label className='label'>
            <span className='label-text'>Email</span>
          </label>
          <input
            placeholder='email@example.com'
            type='text'
            className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
            {...register('username_or_email')}
          />
        </div>
        <div className='form-control w-full mt-4'>
          <label className='label'>
            <span className='label-text'>Password</span>
          </label>
          <input
            placeholder='**********'
            type='password'
            className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
            {...register('password')}
          />
        </div>
        <div className='text-right mt-4'>
          <a
            className='text-[#263DFF]'
            onClick={() => {
              navigate('/aiweb/reset');
            }}
          >
            Forgot password?
          </a>
        </div>
        <button className='btn btn-link bg-[#263DFF] mt-4 w-full'>Login</button>
      </form>
      <div className='text-center mt-5 text-sm px-8 font-semibold'>
        By logging in, you are agreeing to the{' '}
        <a
          className='text-[#263DFF]'
          href='https://www.zettablock.com/terms'
          target='_blank'
          rel='noreferrer'
        >
          Terms of Service
        </a>{' '}
        and confirm that you have read the{' '}
        <a
          className='text-[#263DFF]'
          href='https://www.zettablock.com/privacy'
          target='_blank'
          rel='noreferrer'
        >
          Privacy Policy
        </a>
        .
      </div>
      <div className='px-8 mt-5'>
        <HorizontalLine className='h-0 mb-5' />
        <button
          className='btn btn-outline gap-5 w-full normal-case text-xl'
          onClick={() => openAuth()}
        >
          <DiGithub size='1.5rem' />
          Continue with Github
        </button>
      </div>
    </div>
  );
};
