import axios, { AxiosInstance } from 'axios';
import { Dashboards } from './__gen__/Dashboards';
import { Databases } from './__gen__/Databases';
import { Queries } from './__gen__/Queries';
import { Charts } from './__gen__/Charts';
import { Apikey } from './__gen__/Apikey';
import { Userapis } from './__gen__/Userapis';
import { Stream } from './__gen__/Stream';
import { Queryruns } from './__gen__/Queryruns';
import { Texttosql } from './__gen__/Texttosql';
import { My } from './__gen__/My';
import { Auth } from './__gen__/Auth';
import { Source } from './__gen__/Source';
import { Community } from './__gen__/Community';
import { Workspace } from './__gen__/Workspace';
import { Reaction } from './__gen__/Reaction';
import envs from '../config/envs';
import { setRecoilState } from '../modules/recoil/utils';
import { AuthState, gcookie } from '../modules/auth/AuthState';
import { toast } from 'react-toastify';
import { Gpt } from './__gen__/Gpt';
import { Api } from './__gen__/Api';
import { Chains } from './__gen__/Chains';
import { Triggers } from './__gen__/Triggers';
import { Subscription } from './__gen__/Subscription';
import { ProjectList } from './__gen__/Projectlist';
import { PublicProjectList } from './__gen__/PublicProjectList';
import { CreateProject } from './__gen__/CreateProject';
import { TableList } from './__gen__/TableList';
import { ApiList } from './__gen__/ApiList';
import { MoveTable } from './__gen__/MoveTable';
import { MoveApi } from './__gen__/MoveApi';
import { ChangeProjectStatus } from './__gen__/ChangeProjectStatus';
import { EditTable } from './__gen__/EditTable';
import { ChangeTableStatus } from './__gen__/ChangeTableStatus';
import { ChangeApiStatus } from './__gen__/ChangeApiStatus';
import { TableDetail } from './__gen__/TableDetail';
import { EditProject } from './__gen__/EditProject';
import { ProjectDetail } from './__gen__/ProjectDetail';
import { Search } from './__gen__/Search';
import { EditColumn } from './__gen__/EditColumn';
import { Payment } from './__gen__/Payment';
import { Images } from './__gen__/Images';
import { Image } from './__gen__/Image';
import { ApiDetail } from './__gen__/ApiDetail';
import { EditApiRelatedTable } from './__gen__/EditApiRelatedTable';
import { EditRestApi } from './__gen__/EditRestApi';
import { Recent } from './__gen__/Recent';
import { Queryprompt } from './__gen__/Queryprompt';
// import { Catalog } from './__gen__/Catalog';
import { Dictionary } from './__gen__/Dictionary';
import { Table } from './__gen__/Table';
import { EditQuery } from './__gen__/EditQuery';
import { Project } from './__gen__/Project';
import { Marketplace } from './__gen__/Marketplace';
import { TableFreshness } from './__gen__/TableFreshness';
import { Onboarding } from './__gen__/Onboarding';

export const getAuthHeader = () => {
  const cookieAuth = gcookie.get('auth');
  if (cookieAuth) {
    if (cookieAuth.accessToken) {
      return {
        Authorization: `Bearer ${cookieAuth.accessToken}`,
      };
    }
    if (cookieAuth.username && cookieAuth.password) {
      const token = `${window.btoa(
        `${cookieAuth.username}:${cookieAuth.password}`
      )}`;
      return {
        Authorization: `Basic ${token}`,
      };
    }
  }

  return {};
};

const applyAuthHandler = <T>(client: T) => {
  // @ts-ignore
  (client.instance as AxiosInstance).interceptors.request.use((request) => {
    const headers = getAuthHeader();
    Object.keys(headers).forEach((key) => {
      request.headers.set(key, headers[key]);
    });
    return request;
  });
  return client;
};

const applyErrorHandler = <T>(client: T) => {
  // @ts-ignore
  (client.instance as AxiosInstance).interceptors.response.use(
    (response) => response,
    async (error) => {
      // eslint-disable-next-line
      console.log('global error handler', error);
      if (!error.config.retry && error.response.status === 401) {
        // handle auth
        // eslint-disable-next-line
        const authClient = AuthApi();
        const resp = await authClient.postAuth();

        if (resp.data.accessToken && resp.data.user?.id) {
          setRecoilState(AuthState, {
            accessToken: resp.data.accessToken,
            id: resp.data.user.id,
            name: resp.data.user.name,
            email: resp.data.user.email,
            profileImage: resp.data.user.profileImage,
            tenant: resp.data.user.tenant,
            defaultApiKey: resp.data.user.defaultApiKey,
            subscription: resp.data.subscription,
            whitelisted: resp.data.user.isInAllowList,
          });

          /* retry request here */
          error.config.retry = true;
          error.config.headers = {
            Authorization: `Bearer ${resp.data.accessToken}`,
          };

          return axios.request(error.config);
        }

        setRecoilState(AuthState, null);
      }
      throw error;
    }
  );
  return client;
};

const enhanceClient = <T>(client: T) => {
  return applyErrorHandler(applyAuthHandler(client));
};

export const ChartApi = () => {
  const client = new Charts({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const PublicChartApi = () => {
  const client = new Charts({
    baseURL: envs.ZettaBackendApiPublic,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const PublicQueryApi = () => {
  const client = new Queries({
    baseURL: envs.ZettaBackendApiPublic,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const QueryApi = () => {
  let showPopup = false;
  const client = new Queries({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
    onDownloadProgress: (progressEvent) => {
      if (progressEvent.loaded > 4999999) {
        if (!showPopup) {
          showPopup = true;
          toast(
            'returned data has exceeded a limit of 5MB, the UI only shows the first 5MB data. please refer to our API doc to fetch the whole data',
            {
              toastId: 'too_large',
              autoClose: 5000,
            }
          );
        }
      }
    },
  });
  return enhanceClient(client);
};

export const PreviewApi = () => {
  const client = new Source({
    baseURL: envs.ZettaBackendApiPublic,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const DatabaseApi = () => {
  const client = new Databases({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const DashboardApi = (privateApi = true) => {
  const client = new Dashboards({
    baseURL: privateApi
      ? envs.ZettaBackendApiPrivate
      : envs.ZettaBackendApiPublic,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const StreamApi = (privateApi = true) => {
  let showPopup = false;
  const client = new Stream({
    baseURL: privateApi
      ? envs.ZettaBackendApiPrivate
      : envs.ZettaBackendApiPublic,
    withCredentials: true,
    onDownloadProgress: (progressEvent) => {
      if (progressEvent.loaded > 4999999) {
        if (!showPopup) {
          showPopup = true;
          toast(
            'returned data has exceeded a limit of 5MB, the UI only shows the first 5MB data. please refer to our API doc to fetch the whole data',
            {
              toastId: 'too_large',
              autoClose: 5000,
            }
          );
        }
      }
    },
  });
  return enhanceClient(client);
};

export const QueryRunsApi = () => {
  const client = new Queryruns({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const TextToSQL = () => {
  const client = new Texttosql({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const MyApi = () => {
  const client = new My({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};
export const MyApiV2 = () => {
  const client = new My({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const AuthApi = () => {
  const client = new Auth({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const AuthApiV2 = () => {
  const client = new Auth({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const CommunityApi = () => {
  const client = new Community({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const WorkspaceApi = () => {
  const client = new Workspace({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const UserApiV2 = () => {
  const client = new Userapis({
    baseURL: `${envs.ZettaBackendApiPrivate}`,
    withCredentials: true,
  });
  return enhanceClient(client);
};

// TODO: replace it later. this is for demo perpose
export const UsersApi = () => {
  const client = new Charts({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const ApiKey = () => {
  const client = new Apikey({
    baseURL: `${envs.ZettaBackendApiPrivate}`,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const ApiKeyApiV2 = () => {
  const client = new Apikey({
    baseURL: `${envs.ZettaBackendApiPrivateV2}`,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const ReactionApi = () => {
  const client = new Reaction({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const GptApi = () => {
  const client = new Gpt({
    baseURL: envs.ZettaBackendApiPublic,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const ApiView = () => {
  const client = new Api({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const TriggersApi = () => {
  const client = new Triggers({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const ChainsApi = () => {
  const client = new Chains({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const SubscriptionApi = () => {
  const client = new Subscription({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const ProjectListApi = () => {
  const client = new ProjectList({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const PublicProjectListApi = () => {
  const client = new PublicProjectList({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const CreateProjectApi = () => {
  const client = new CreateProject({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const TableListApi = () => {
  const client = new TableList({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const ApiListApi = () => {
  const client = new ApiList({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const MoveTableApi = () => {
  const client = new MoveTable({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};
export const MoveApiApi = () => {
  const client = new MoveApi({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const ChangeProjectStatusApi = () => {
  const client = new ChangeProjectStatus({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const ChangeTableStatusApi = () => {
  const client = new ChangeTableStatus({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const ChangeApiStatusApi = () => {
  const client = new ChangeApiStatus({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const EditTableApi = () => {
  const client = new EditTable({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const EditProjectApi = () => {
  const client = new EditProject({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const TableDetailApi = () => {
  const client = new TableDetail({
    baseURL: envs.ZettaBackendApiPublic,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const ProjectDetailApi = () => {
  const client = new ProjectDetail({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const SearchApi = () => {
  const client = new Search({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const EditColumnApi = () => {
  const client = new EditColumn({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const PaymentApi = () => {
  const client = new Payment({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const ImagesApi = () => {
  const client = new Images({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const ImageApi = () => {
  const client = new Image({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const ApiDetailApi = () => {
  const client = new ApiDetail({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const EditApiRelatedTableApi = () => {
  const client = new EditApiRelatedTable({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const EditRestApiApi = () => {
  const client = new EditRestApi({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const RecentApi = () => {
  const client = new Recent({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const QuerypromptApi = () => {
  const client = new Queryprompt({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const DictionaryApi = () => {
  const client = new Dictionary({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const TableApi = () => {
  const client = new Table({
    baseURL: envs.ZettaBackendApiPublic,
    withCredentials: true,
  });
  return enhanceClient(client);
};
export const TableApiV2 = () => {
  const client = new Table({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const EditQueryApi = () => {
  const client = new EditQuery({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const ProjectApi = () => {
  const client = new Project({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const MarketplaceApi = () => {
  const client = new Marketplace({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const TableFreshnessApi = () => {
  const client = new TableFreshness({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const OnboardingApi = () => {
  const client = new Onboarding({
    baseURL: envs.ZettaBackendApiPrivateV2,
    withCredentials: true,
  });
  return enhanceClient(client);
};
