/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable max-classes-per-file */
import { IsOptional, ValidateNested } from 'class-validator';
import { ClassConstructor, Type } from 'class-transformer';
import { httpClient, IsOptionalString } from '../../../http-client';
import { AxiosRequestConfig } from 'axios';

export namespace authResetDTO {
  export interface Req {
    token: string;
    password: string;
  }

  export class Res {
    @IsOptionalString()
    data?: string;
  }

  export class Response {
    @IsOptional()
    @ValidateNested()
    @Type(() => Res)
    res?: Res | null;
  }
}

export const authReset = (
  reqData: authResetDTO.Req,
  config = {} as AxiosRequestConfig & {
    dto: ClassConstructor<authResetDTO.Res>;
  }
) => {
  config.dto = authResetDTO.Res;
  return httpClient.post<authResetDTO.Res>(`/user/password/${reqData.token}`, reqData, config);
};
