import { Alert, Card, Table } from 'antd';
import { useAIAuth } from '../../../components/OpenAuth/useOpenAuth';
import { useMemo } from 'react';
import { ColumnProps } from 'antd/es/table';
import moment from 'moment';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tokenColumns: ColumnProps<any>[] = [
  {
    dataIndex: 'name',
    title: 'Name',
  },
  {
    dataIndex: 'id',
    title: 'Value',
    render: (text) => {
      return (
        <div>
          {text.slice(0, 3)}...{text.slice(-4)}
          <CopyToClipboard text={text}>
            <CopyOutlined
              className='ml-2'
              rev={undefined}
              onClick={() => {
                toast.success('Copied to clipboard');
              }}
            />
          </CopyToClipboard>
        </div>
      );
    },
  },
  {
    dataIndex: 'is_default',
    title: 'Default',
    render: (text) => {
      return text ? 'Yes' : 'No';
    },
  },
  {
    dataIndex: 'refreshed_at',
    title: 'Last Refreshed Date',
    render: (text) => {
      return text ? moment(text).fromNow() : '--';
    },
  },
  {
    dataIndex: 'created_at',
    title: 'Created Date',
    render: (text) => {
      return text ? moment(text).fromNow() : '--';
    },
  },
  {
    dataIndex: 'permission',
    title: 'Permission',
    render: (text) => {
      if (text === 0) {
        return 'Write';
      }
      return '--';
    },
  },
];
export default function AuthTokens() {
  const [auth] = useAIAuth();

  const tokens = useMemo(() => {
    if (auth?.profile?.tokens) {
      return auth?.profile?.tokens;
    }
    return [];
  }, [auth]);

  return (
    <main>
      <h3 className='text-lg font-bold my-4'>Access Tokens</h3>
      <Card
        bordered={false}
        style={{ boxShadow: 'none' }}
        headStyle={{ borderBottom: '0 none', padding: 0 }}
        bodyStyle={{ padding: 0 }}
        title={<h5 className='text-lg font-bold'>User Access Tokens</h5>}
      >
        <p>
          Access tokens authenticate your identity to the Zettablock AI and
          allow applications to perform actions based on token permissions.
        </p>
        <Alert
          className='my-4'
          type='warning'
          message='Do not share your Access Tokens with anyone; we regularly check for leaked Access Tokens and remove them immediately.'
        />
        <Table
          columns={tokenColumns}
          dataSource={tokens}
          pagination={false}
        ></Table>
      </Card>
    </main>
  );
}
