import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  Radio,
  Space,
} from 'antd';
import { useNavigator } from '../../../useNavigator';
import {
  assetCreate,
  assetCreateDTO,
} from '../../../modules/apis/neo/assetCreate';
import { RepositoryType } from '../../../modules/apis/types';
import { OptionGroup } from '../../../components/OptionGroup';
import {
  licenseOptions,
  modalityTypeOptions,
} from '../../../modules/apis/options';
import { toast } from 'react-toastify';

const typeOptions = [
  {
    value: false,
    label: (
      <div>
        <h5>Public</h5>
        <p>
          Anyone on the internet can see this dataset. Only you (personal
          dataset) or members of your organization (organization dataset) can
          commit.
        </p>
      </div>
    ),
  },
  {
    value: true,
    label: (
      <div>
        <h5>Private</h5>
        <p>
          Only you (personal dataset) or members of your organization
          (organization dataset) can see and commit to this dataset.
        </p>
      </div>
    ),
  },
];

export default function DatasetCreate() {
  const navigator = useNavigator('/aiweb/datasets');
  const [form] = Form.useForm<assetCreateDTO.Req>();

  return (
    <main className='p-5'>
      <Breadcrumb
        items={[
          {
            title: <a>← Back</a>,
            onClick: () => navigator.go(-1),
          },
        ]}
      />
      <Card
        bordered={false}
        style={{
          boxShadow: 'none',
          width: '50%',
          minWidth: 500,
          maxWidth: 800,
          margin: '0 auto',
        }}
        headStyle={{ borderBottom: '0 none' }}
        title={
          <div className='text-center'>
            <h3 className='text-lg'>Create a new dataset repository</h3>
            <p className='text-sm font-normal text-gray-600'>
              A repository contains all dataset files, including the revision
              history.
            </p>
          </div>
        }
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={(values) => {
            values.type = RepositoryType.RepositoryTypeDataset;
            assetCreate(values).then(
              () => {
                toast.success('Dataset create successfully');
                navigator.push('/aiweb/datasets');
              },
              (err) => {
                toast.error(err.message);
              }
            );
          }}
        >
          {/* <Row gutter={24}>
            <Col span={6}>
              <Form.Item label='Owner' name='owner'>
                <Input placeholder='Owner Name' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Dataset name' name='name'>
                <Input placeholder='New Dataset Name' />
              </Form.Item>
            </Col>
          </Row> */}
          <Form.Item label='Dataset name' name='name'>
            <Input placeholder='New Dataset Name' />
          </Form.Item>
          <Form.Item label='License' name='license'>
            <Radio.Group options={licenseOptions} />
          </Form.Item>
          <Form.Item label='Type' name='private'>
            <Radio.Group>
              <Space direction='vertical'>
                {typeOptions.map((item, index) => {
                  return (
                    <div key={index} className='border border-gray-300 p-3'>
                      <Radio value={item.value}>{item.label}</Radio>
                    </div>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='Select category' name='modality'>
            <OptionGroup options={modalityTypeOptions} />
          </Form.Item>
          <Form.Item label='Description' name='description'>
            <Input.TextArea placeholder='Input Description' rows={6} />
          </Form.Item>
          <Form.Item>
            <Alert
              type='info'
              message='Once your dataset is created, you can upload your files using the web interface or git.'
            />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type='primary' htmlType='submit'>
              Create Dataset
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </main>
  );
}
