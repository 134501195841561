import { useContext, useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Layout, Menu, MenuProps, theme } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { track } from '../v2/tracking';
import { GlobalSettingsContext } from '../v2/context/global-settings';
import Icon from '../v2/components/Icon';
import Logo from '../components/app/Logo';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAIAuth } from './components/OpenAuth/useOpenAuth';
import { useNavigator } from './useNavigator';
import { Avatar } from './components/Avatar';
import { IoIosSearch } from 'react-icons/io';

export const routes = [];
export function RootLayout({
  children,
  menus,
  defaultOpenKeys,
}: {
  children?: React.ReactNode;
  menus: MenuProps['items'];
  defaultOpenKeys?: string[];
}) {
  const navigator = useNavigator();
  const location = useLocation();
  const [auth] = useAIAuth();
  const { toggleMiniSidebar, isMiniSidebar } = useContext(
    GlobalSettingsContext
  );
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const contentRef = useRef<HTMLElement>(null);
  useEffect(() => {
    track(`Page View: ${location.pathname}`, {
      path: location.pathname,
      search: location.search,
      user: auth?.user?.id,
      email: auth?.user?.email,
    });
    contentRef.current?.scrollTo(0, 0);
  }, [location]);

  return (
    <Layout className='min-h-screen'>
      <Sider
        theme='light'
        width={200}
        collapsedWidth={60}
        trigger={null}
        collapsible
        collapsed={isMiniSidebar}
        className='border-r overflow-hidden transition-none'
        style={{ background: '#f8f7fa' }}
      >
        <div
          className={classNames(
            'pl-[22px] pr-[17px] h-[40px] text-[12px] flex justify-center box-content'
          )}
        >
          <Link
            to='/aiweb/models'
            className={classNames('my-[1px]', { hidden: isMiniSidebar })}
          >
            <Logo className='w-[123px]' />
          </Link>
          <Icon
            className={classNames('h-[24px] my-[8px]', {
              hidden: !isMiniSidebar,
            })}
            iconName='favicon'
            iconType='png'
          />
        </div>
        <div className='border-b'></div>
        <div className='flex justify-center'>
          <Menu
            theme='light'
            mode='inline'
            className='p-4 transition-none'
            inlineIndent={0}
            style={{ background: '#f8f7fa', width: '100%' }}
            defaultOpenKeys={defaultOpenKeys}
            expandIcon={null}
            defaultSelectedKeys={[location.pathname]}
            onSelect={(item) => {
              navigator.push(item.key);
            }}
            items={menus}
          />
        </div>
      </Sider>
      <Layout className='bg-white'>
        <Header
          style={{ padding: 0, height: 40, background: colorBgContainer }}
        >
          <div className='flex justify-between h-10 border-b items-center bg-[#fff] box-content	'>
            <div className='flex items-center'>
              <div
                className='h-[40px] w-[40px] flex justify-center items-center cursor-pointer'
                onClick={toggleMiniSidebar}
              >
                <div className='w-[30px] h-[30px] flex justify-center items-center bg-[#f4f4f4] rounded-[4px] '>
                  <Icon iconName='menu' className='w-[16px] h-[16px]' />
                </div>
              </div>
              <div
                className={classNames(
                  'flex items-center px-3 pl-[10px] bg-[#f4f4f4] rounded-[4px] ml-[5px] w-[300px]'
                )}
              >
                <IoIosSearch size={16} />
                <input
                  type='text'
                  placeholder='Search models, datasets...'
                  className='h30 h-[30px] focus:outline-none input w-full bg-transparent text-[12px] pl-[10px] py-0 outline-none	'
                />
              </div>
            </div>
            <div className='flex-1'></div>
            <div className='flex items-center gap-[20px] p-4'>
              <div className='flex items-center gap-[30px]'>
                <a
                  href='https://www.zettablock.com/pricing'
                  target='_blank'
                  rel='noreferrer'
                  className='text-[#2B2F33] text-[12px] font-[500]'
                >
                  Pricing
                </a>
                <a
                  href='https://docs.zettablock.com/reference/api-intro'
                  target='_blank'
                  rel='noreferrer'
                  className='text-[#2B2F33] text-[12px] font-[500]'
                >
                  API
                </a>
                <a
                  href='https://docs.zettablock.com/'
                  target='_blank'
                  rel='noreferrer'
                  className='text-[#2B2F33] text-[12px] font-[500]'
                >
                  Docs
                </a>
              </div>
              <Avatar
                className='w-[28px]'
                onClick={() => {
                  navigator.push('/aiweb/profile');
                }}
              />
            </div>
          </div>
        </Header>
        <Content
          ref={contentRef}
          style={{
            margin: 16,
            padding: '0 24px 24px',
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {children || <Outlet />}
        </Content>
      </Layout>
    </Layout>
  );
}
